import React, { createContext, useContext, useState } from "react";

const TabNavigationContext = createContext();

export const useTabNavigationContext = () => useContext(TabNavigationContext);

export const TabNavigationProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const navigateToTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <TabNavigationContext.Provider value={{ activeTab, navigateToTab }}>
      {children}
    </TabNavigationContext.Provider>
  );
};
